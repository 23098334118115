import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useMedia } from 'use-media'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// Images
import CircleArrow from 'img/circlearrow.inline.svg'
import BlockDefault from 'components/elements/Blocks/BlockDefault'

const Usp = styled.div`
  position: relative;
  border: 2px solid ${({ theme }) => theme.color.secondaryDark};
  border-radius: 62px;
  padding: 1rem 1rem 2.2rem 1rem;
`

const ArrowContainer = styled(motion.div)`
  position: absolute;
  bottom: -36px;
  left: 50%;
  margin-left: -31px;
  &:hover {
    cursor: pointer;
  }
  & svg {
    @media (max-width: 992px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 992px) {
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: 30px;
    @media (max-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.post} !important;
      line-height: 20px !important;
    }
  }
  & p {
    overflow: hidden !important;
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
  }
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
  }
`

const StyledBlockDefault = styled(BlockDefault)`
  overflow: hidden;
`

interface UspWithPopupProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usp
}

const UspWithPopup: React.FC<UspWithPopupProps> = ({ fields }) => {
  const isMobileDevice = useMedia({ maxWidth: 991 })

  useEffect(() => {
    setCurrent(isMobileDevice ? 0 : 1)
  }, [isMobileDevice])

  const [current, setCurrent] = useState<number | null>(null)

  return (
    <section className="mb-5">
      <div className="container">
        <div className="row justify-content-center w-100">
          {fields.usp?.map((usp, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-lg-4 mb-5 mb-lg-0" key={index}>
              <Usp>
                <Content content={usp?.description} />
                <ArrowContainer
                  onHoverStart={() => setCurrent(index)}
                  onTap={() => setCurrent(index)}
                >
                  <CircleArrow />
                </ArrowContainer>
              </Usp>
              <AnimatePresence>
                {current === index && (
                  <motion.div
                    className="d-flex"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      type: 'spring',
                      stiffness: 100,
                      damping: 12,
                    }}
                  >
                    <StyledBlockDefault noShadow className="mt-5">
                      <div className="p-3 pt-0">
                        <Content
                          content={fields.usp![current]?.popupdescription}
                        />
                      </div>
                    </StyledBlockDefault>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default UspWithPopup
